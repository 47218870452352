<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="clinics">Clinics</v-tab>
            <v-tab key="Locations">Locations</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="clinics">
              <v-master-clinic></v-master-clinic>
            </v-tab-item>
            <v-tab-item key="locations">
              <v-master-location></v-master-location>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
